import { Injectable } from "@angular/core";
import { SessionService } from "../../session/session.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoggedInGuardService {
  constructor(
    private m_SessionService: SessionService,
    private m_Router: Router
  ) {}

  //Check if user is logged in
  //If not, redirect to login page
  async canActivate(): Promise<boolean> {
    await this.m_SessionService.checkForToken();

    if (!this.m_SessionService.LoggedIn) {
      return true;
    } else {
      this.m_Router.navigate(["/home"]);
      return false;
    }
  }
}
